import Observer from "./Observer";
import { getTenantId } from "./Tenant";

export const root = document.location.port == '3000' ? 'https://localhost:7180/' : '/';

const buildQueryParams = (params: any, cmd: string) => params ? (cmd.indexOf('?') < 0 ? '?' : '&') + buildUrlQuery(params) : '';
export const buildUrl = (cmd: string, params?: any) => `${root}api/${getTenantId() || 1}/${cmd}${buildQueryParams(params, cmd)}`;
export function buildUrlQuery(obj: any) {
	var str = [];
	for (var p in obj) {
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	}

	return str.join("&");
}

const observer = new Observer();
interface ProxyEvent {
	method: 'GET' | 'POST'
	cmd: string
	params?: any
	body?: any
	response: any
}

const Proxy = {
	on(cmd: string, fn: (e: ProxyEvent) => any) {
		return observer.listen('executed', (e: ProxyEvent) => {
			if (e.cmd.indexOf(cmd) >= 0) {
				fn(e);
			}
		});
	},

	get(cmd: string, params?: any) {
		return fetch(buildUrl(cmd, params), {
			credentials: 'include'
		}).then(x => x.json()).then(x => {
			observer.fire('executed', [{
				method: 'GET',
				cmd,
				params,
				response: x
			}]);
			return x;
		});
	},

	post(cmd: string, body?: any, params?: any) {
		return fetch(buildUrl(cmd, params), {
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body),
			method: 'POST',
			credentials: 'include'
		}).then(x => x.json()).then(x => {
			observer.fire('executed', [{
				method: 'POST',
				cmd,
				params,
				body,
				response: x
			}]);
			return x;
		});
	}
};

export default Proxy;