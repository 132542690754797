import route from '../../../Router';
import NavLink from '../../../components/NavLink';
import { ColumnData } from '../../../components/data/DataTable';
import DataView from '../../../components/data/DataView';
import { Position } from '../../../models/Types';
import { PositionForm } from './PositionForm';

class PositionListDto extends Position {
    Employees?: string
}

const columns: ColumnData<PositionListDto>[] = [{
    label: 'Наименование',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Сотрудники',
    name: 'Employees',
    filter: true,
    renderer: val => (val as [])?.join(', ')
}, {
    name: 'Id',
    sx: () => {
        return { fontSize: undefined }
    },
    renderer: (id, obj, theme) => <NavLink route={[['id', id], ['copy', true], ['hideForm']]}>Копировать</NavLink>,
}];

export default function PositionView() {
    return <DataView<Position>
        title="Должность"
        titleMultiple="Должности"
        typeId="Position"
        form={(id, apiRef) => <PositionForm id={id} apiRef={apiRef} copy={route.get('copy')} />}
        tableProps={{ columns }} />;
}