import { ColumnData } from "../../../components/data/DataTable";
import { personListColumns, PersonListDto } from "../../../components/person/PersonList";
import PositionCombo, { positionStore } from "../positions/PositionCombo";

export const employeeListColumns: ColumnData<PersonListDto>[] = [...personListColumns];
employeeListColumns.splice(2, 0, {
    width: 120,
    label: 'Должности',
    name: 'PositionIds',
    type: 'array',
    store: positionStore,
    filter: (onChange) => <PositionCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => positionStore.data?.filter(x => x.Id == v).map(x => x.Value)).join(', ')
});