import { Typography } from '@mui/material';
import NavLink from '../components/NavLink';
import { versions } from '../screens/version';
import { hierarchicalMenu } from './AppRoute';
import Drawer from './Drawer';

export default function AppDrawer({ open }: { open: boolean }) {
    return <Drawer key={'drawer' + open} open={open} menu={hierarchicalMenu} showLogo={true} title="Главное меню">
        <div style={{ flex: 1, zIndex: -1 }} />

        {!open ? null : <Typography variant="caption" style={{ textAlign: 'center' }}>Версия <NavLink route={[['page', 'version'], ['id'], ['tab']]} prefix="">{versions[0].version}</NavLink></Typography>}
    </Drawer>
}