import { Box } from "@mui/material";
import { useAppRoute } from "../../nav/AppRoute";
import Drawer from "../../nav/Drawer";
import route from "../../Router";
import projectMenu from "./ProjectMenu";
import Guide from "../../guides/Guide";
import { isMobile } from "../../utils";

route.addRoute('project')
    .addUrlPath('page')
    .addUrlPath('subpage')
    .addUrlPath('id')
    .addUrlPath('tab');

route.hashChanged();

function Body() {
    const [route, path, current, menuItem] = useAppRoute(projectMenu);
    return <>{menuItem?.element && menuItem.element()}</>;
}

export default function ProjectView() {
    return <>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', ml: -2 }}>
            <Drawer open={true} menu={projectMenu} title="Настройки ЖК" />
            <Box className="body"
                sx={{ ml: 2, display: 'flex', flex: 1, flexDirection: 'column', flexGrow: 1, position: 'relative' }}
                style={{ overflow: isMobile() ? undefined : 'hidden' }}>
                <Body />
            </Box>
        </Box>
        <Guide guide="projectMenu" />
    </>;
}