import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../../components/form/RemoteCombo";
import { Tariff } from "../../../models/Types";

const cmd = 'TariffSuggest';
const valueField = 'Name';

export const tariffStore = configureSuggestStore<Tariff>(cmd, valueField, 30 * 60, ['TariffSave']);

export default function TariffCombo({ name, value, onDataLoad, onChange, allowAdd, multiple, filter, ...props }: RemoteComboProps<Tariff>) {
    return <RemoteCombo<Tariff>
        name={name}
        store={tariffStore}
        typeId="Tariff"
        allowAdd={allowAdd}
        value={value}
        multiple={multiple}
        onDataLoad={onDataLoad}
        onChange={onChange}
        filter={filter} />;
}