import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../../components/form/RemoteCombo";
import { ISuggest, Service } from "../../../models/Types";

const cmd = 'ServiceList';

export const serviceStore = configureSuggestStore<Service>(cmd, "Value", 30 * 60, ['ServiceSave']);

export const serviceTextRenderer = (x: ISuggest<string, number, Service>) => `${x.Obj?.Name} (${x.Obj?.Price} руб./${x.Obj?.Unit})`;

export default function ServiceCombo({ name, value, onDataLoad, onChange, allowAdd, multiple, filter, ...props }: RemoteComboProps<Service>) {
    return <RemoteCombo<Service>
        name={name}
        store={serviceStore}
        typeId="Service"
        allowAdd={allowAdd}
        value={value}
        multiple={multiple}
        itemRenderer={serviceTextRenderer}
        onDataLoad={onDataLoad}
        onChange={onChange}
        filter={filter} />;
}