import { PersonForm } from "../../../components/person/PersonForm";
import { TextInput } from "../../../components/form/TextInput";
import { Person } from "../../../models/Types";
import PositionCombo from "../positions/PositionCombo";
import { ReactNode } from "react";
import { ButtonProps } from "@mui/material";

export class EmployeeForm extends PersonForm {
    protected getTypeId() {
        return 'Employee';
    }

    protected getTypeName(): React.ReactNode {
        return 'Сотрудник';
    }

    buildDeleteButton(text?: ReactNode, props?: ButtonProps) {
        return super.buildDeleteButton(text || 'Удалить Сотрудника', props);
    }

    protected buildPersonItems(entity?: Person) {
        const items = super.buildPersonItems.call(this, entity);
        items.splice(2, 0,
            <TextInput
                required={true}
                value={entity?.PositionIds}
                label="Должности"
                name="PositionIds"
                onChange={this.onChange}
                validator={v => (v as number[])?.length ? undefined : 'Необходимо выбрать должности сотрудника'}
                refValidator={val => this.addValidator('PositionIds', 'PositionIds', v => val(v))}
                component={(onChange, error) => <PositionCombo
                    name="PositionIds"
                    multiple={true}
                    error={!!error}
                    value={entity?.PositionIds}
                    onChange={onChange} />} />);

        return items;
    }
}