import { Box, Button, Chip, Paper, Stack, SxProps, Theme, Typography } from "@mui/material";
import { CSSProperties } from "react";
import NavLink from "../../components/NavLink";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import DataTable from "../../components/data/DataTable";
import { Bill, MeasureBillDto, PeriodicServiceBill, ServiceBill } from "../../models/Types";
import { isMobile, numberFormat, periodNumToText } from "../../utils";
import { houseStore } from "../houses/HouseCombo";
import { periodicServiceStore } from "../project/periodicService/PeriodicServiceCombo";
import { serviceStore } from "../project/service/ServiceCombo";
import { tariffStore } from "../project/tariff/TariffCombo";

export function billParse(d?: any) {
    const dto = (d || {}) as Bill;
    d?.CreateDate && (dto.CreateDate = new Date(d.CreateDate));
    return dto;
}

export function billDetailParse<T>(d: T) {
    const dto = d as any;
    dto.CreateDate && (dto.CreateDate = new Date(dto.CreateDate));
    return d
}

export class BillForm extends AbstractTypedDataForm<Bill, {}, { houseId?: number }> {
    protected getTypeId(): string {
        return 'Bill';
    }
    protected getTypeName(): React.ReactNode {
        return 'Начисление';
    }

    buildButtons() {
        return [];
    }

    responseToState(response: any) {
        var res = super.responseToState(response);
        res.entity?.MeasureBills?.forEach((x: MeasureBillDto) => {
            x.Id = x.Id || x.TariffId;
            x.CreateDate && (x.CreateDate = new Date(x.CreateDate));
        });
        return res;
    }

    protected createEntity() {
        const result = billParse();
        result.MeasureBills = [];
        return result;
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...super.getFormStyle(),
            flexDirection: 'column',
            alignItems: 'stretch',
            overflow: 'hidden'
        };
    }

    protected buildItems(entity?: Bill | undefined): React.ReactNode {
        const house = houseStore.data?.find(x => x.Id === entity?.HouseId);
        return <>
            <Paper style={isMobile() ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { padding: 0 }}>
                <div/>
                <Box display="flex" flexDirection="row" gap={2} justifyContent="space-between" px={2} overflow="auto" style={styles.paper}>
                    <Stack>
                        <Typography variant="subtitle2">Адрес</Typography>
                        {house?.Value}
                    </Stack>

                    <Stack>
                        <Typography variant="subtitle2">Счет</Typography>
                        {house?.Id}
                    </Stack>

                    {entity?.PeriodNum ? <Stack>
                        <Typography variant="subtitle2">Период</Typography>
                        {periodNumToText(entity.PeriodNum)}
                    </Stack> : null}

                    <Stack>
                        <Typography variant="subtitle2">Сумма</Typography>
                        {entity?.Amount ? numberFormat(entity.Amount) : undefined}
                    </Stack>

                    <Stack gap={2}>
                        <Chip size="small" color={entity?.PaymentId ? 'success' : 'error'} label={(entity?.PaymentId ? 'О' : 'Не о') + 'плачено'} />

                        <Button variant="contained">Оплатить</Button>
                    </Stack>
                </Box>

                {entity?.MeasureBills?.length ? <DataTable<MeasureBillDto>
                    style={{ flex: 1, margin: 0, padding: 0, overflow: 'auto' }}
                    data={entity.MeasureBills.map(billDetailParse)}
                    columns={[{
                        name: "TariffId",
                        label: 'Ресурс',
                        store: tariffStore,
                        //filter: (onChange) => <TariffCombo onChange={e => onChange(e.target.value)} />,
                        renderer: (val, obj) => <NavLink route={[['page', 'project/tariff'], ['id', val]]}>{tariffStore.data?.find(x => x.Id == val)?.Value}</NavLink>
                    }, {
                        name: "CreateDate",
                        label: 'Дата подачи',
                        type: 'date'
                    }, {
                        name: "Value",
                        label: 'Показание',
                        renderer: (val, obj) => <NavLink route={[['page', 'measure'], ['id', obj.MeasureId]]}>{val}</NavLink>
                    }, {
                        name: "ValueDiff",
                        label: 'Изменение'
                    }, {
                        name: "Price",
                        label: 'Тариф'
                    }, {
                        name: "Sum",
                        label: 'Сумма'
                    }]}
                /> : null}

                {entity?.ServiceBills?.length ? <DataTable<ServiceBill>
                    style={{ flex: 1, margin: 0, padding: 0, overflow: 'auto' }}
                    data={entity.ServiceBills.map(billDetailParse)}
                    columns={[{
                        name: "ServiceId",
                        label: 'Услуга',
                        store: serviceStore,
                        //filter: (onChange) => <TariffCombo onChange={e => onChange(e.target.value)} />,
                        renderer: (val, obj) => <NavLink route={[['page', 'project/service'], ['id', val]]}>{serviceStore.data?.find(x => x.Id == val)?.Obj?.Name}</NavLink>
                    }, {
                        name: "CreateDate",
                        label: 'Дата оказания',
                        type: 'date'
                    }, {
                        name: "Count",
                        label: 'Количество'
                    }, {
                        name: "Price",
                        label: 'Цена'
                    }, {
                        name: "Sum",
                        label: 'Сумма'
                    }]}
                /> : null}

                {entity?.PeriodicServices?.length ? <DataTable<PeriodicServiceBill>
                    style={{ flex: 1, margin: 0, padding: 0, overflow: 'auto' }}
                    data={entity.PeriodicServices.map(billDetailParse)}
                    columns={[{
                        name: "PeriodicServiceId",
                        label: 'Услуга',
                        store: periodicServiceStore,
                        //filter: (onChange) => <TariffCombo onChange={e => onChange(e.target.value)} />,
                        renderer: (val, obj) => <NavLink route={[['page', 'project/periodicService'], ['id', val]]}>
                            {periodicServiceStore.data?.find(x => x.Id == val)?.Obj?.Name}
                        </NavLink>
                    }, {
                        name: "Value",
                        label: 'Значение'
                    }, {
                        name: "Price",
                        label: 'Цена'
                    }, {
                        name: "Sum",
                        label: 'Сумма'
                    }]}
                /> : null}

                <Box px={2} sx={{display: 'flex'} }>
                    <Button variant="outlined" sx={{ ml: 'auto' }}>Распечатать квитанцию</Button>
                </Box>

                <div />
            </Paper>
        </>;
    }
}

const styles = {
    paper: { display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 'none' } as CSSProperties
}