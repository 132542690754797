import DataView from '../../../components/data/DataView';
import { PersonListDto } from "../../../components/person/PersonList";
import { EmployeeForm } from './EmployeeForm';
import { employeeListColumns } from './EmployeeList';

export default function EmployeeView() {
    return <DataView<PersonListDto>
        title="Сотрудник"
        titleMultiple="Сотрудники"
        typeId="Employee"
        form={(id, apiRef) => <EmployeeForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{
            columns: employeeListColumns,
            dataParser: PersonListDto.parse
        }} />;
}