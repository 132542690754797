import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../components/form/RemoteCombo";
import { House } from "../../models/Types";

const cmd = 'HouseSuggest';
const valueField = 'Name';

export const houseStore = configureSuggestStore<House>(cmd, valueField, 30 * 60, ['HouseSave']);

export default function HouseCombo({ name, value, onDataLoad, filter, onChange, allowAdd, multiple, selectFirstOnLoad }: RemoteComboProps<House>) {
    return <RemoteCombo<House>
        name={name}
        store={houseStore}
        typeId="House"
        allowAdd={allowAdd}
        value={value}
        multiple={multiple}
        onDataLoad={onDataLoad}
        selectFirstOnLoad={selectFirstOnLoad}
        filter={filter}
        onChange={onChange} />;
}