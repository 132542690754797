import { Add, Settings } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Typography, styled } from '@mui/material';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { CallBackProps } from 'react-joyride';
import route from '../Router';
import BaseUserComponent from '../components/base/BaseUserComponent';
import Guide from '../guides/Guide';
import { getUser, isAuthentificated, setTenantId } from '../models/Identity';
import { getTenantId } from '../models/Tenant';
import { Tenant } from '../models/Types';
import { isMobile } from '../utils';

export const CreateNewTenant = () => <>
    <ListItemIcon>
        <Add />
    </ListItemIcon>
    <ListItemText>Создать ЖК</ListItemText>
</>

const TenantSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    paddingRight: 10,
    minWidth: 200,
    maxWidth: 350,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        backgroundColor: 'transparent'
    },
    '& .MuiList-root': {
        padding: '.25rem',
    },
    '& .MuiSelect-select': {
        backgroundColor: 'transparent'
    },
    '& > .MuiSvgIcon-root': {
        color: '#fff',
        paddingLeft: 5,
        borderLeft: '1px solid',
        borderColor: theme.palette.primary.light
    },
})) as any as typeof Select;

export default class TenantSelectorComponent extends BaseUserComponent<{}, { guideIndex?: number }> {
    constructor(p: any) {
        super(p);

        this.onOpen = this.onOpen.bind(this);
        this.onGuide = this.onGuide.bind(this);
    }

    onChange(e: any) {
        const id = parseInt(e.target.value);
        if (id) {
            setTenantId(id);
        } else {
            route.setPath('/project/project/new')
        }
    }

    buildMenuItem(x: Tenant) {
        return <MenuItem key={x.Id} value={x.Id.toString()}>
            <Typography sx={{ flex: 1 }}>{x.Name}</Typography>
            <IconButton onClick={() => route.setPath('/project/project/' + x.Id)}><Settings /></IconButton >
        </MenuItem>;
    }

    buildMenu() {
        return getUser().Tenants.map(x => this.buildMenuItem(x));
    }

    buildNewItem(props?: MenuItemProps) {
        return <MenuItem key="new" value="0" onClick={this.onChange} {...props}>
            <CreateNewTenant />
        </MenuItem>;
    }

    renderValue(v: string) {
        if (v) {
            var id = parseInt(v);
            return getUser().Tenants
                .filter(x => x.Id == id)
                .map(x => x.Name)[0];
        }
    }

    onOpen(event: React.SyntheticEvent) {
        this.setState({ guideIndex: 1 });
    }

    onGuide(data: CallBackProps) {
    }

    render() {
        if (!isAuthentificated()) {
            return <></>;
        }

        if (!getUser().Tenants?.length) {
            return <>
                {this.buildNewItem({ id: 'tenant-selector-new' })}
                <Guide guide="tenantCreate" />
            </>
        }

        const id = getTenantId()?.toString();
        const sx = isMobile() ? { minWidth: 50, width: 'auto' } : { flex: 'none', width:'auto' };

        return <TenantSelect id="tenant-selector" key={id} value={id} onChange={this.onChange.bind(this)} sx={sx} size="small"
            MenuProps={{
                sx: {
                    zIndex:10000
                }
            } }
            renderValue={this.renderValue}
            onOpen={this.onOpen}>
            {this.buildMenu()}
            <Divider />
            {this.buildNewItem()}
        </TenantSelect>;
    }
}