import { Box, Button, Checkbox, FormControlLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../../components/data/DataForm";
import { FormTextInput } from "../../../components/form/TextInput";
import { Position } from "../../../models/Types";
import { isMobile, isString } from "../../../utils";
import './positions.css';

const styles = {
    input: {
        borderRadius: 0,
        m: '-1px'
    },
    inputInner: {
        fontSize: '.95em',
        color: '#5B5B5B',
        '-webkit-text-fill-color': '#5B5B5B'
    }
}

const variants: any = {
    0: 'Запрещено',
    1: 'Разрешено',
    2: 'Если ответственный'
}

const colors: any = {
    0: 'error',
    1: 'success.main',
    2: 'primary'
}

type GRInfo = {
    name: string,
    variants: number[],
};

const keys: {
    [group: string]: {
        prefix: string,
        variants: number[],
        keys: {
            [key: string]: string | GRInfo
        }
    }
} = {
    'Данные жильцов': {
        prefix: '',// 'Clients',
        variants: [0, 1],
        keys: {
            'Measure': 'Показания',
            'Request': {
                name: 'Заявки',
                variants: [0, 1, 2]
            },
            'ProvidedService': 'Оказавыемые услуги',
            'Bill': 'Начисления',
            'Payment': 'Платежи',
            'EntryPass': 'Пропуска'
        }
    },
    'Управление ЖК': {
        prefix: '',// 'Manage',
        variants: [0, 1],
        keys: {
            'Tariff': 'Тарифы',
            'Service': 'Услуги',
            'PeriodicService': 'Регулярные услуги',
            'Location': 'Локации',
            'Post': 'Новости',
            'Meter': 'Счетчики',
            'Inhabitant': 'Жильцы',
            'House': 'Домовладения'
        }
    },
    'Управление Аккаунтом ЖК': {
        prefix: '',// 'Manage',
        variants: [0, 1],
        keys: {
            'Position': 'Должности (роли)',
            'Employee': 'Сотрудники'
        }
    }
}

export class PositionForm extends AbstractTypedDataForm<Position> {
    constructor(p: any) {
        super(p);

        this.onKeyChange = this.onKeyChange.bind(this);
    }

    protected getTypeId(): string {
        return 'Position';
    }

    protected getTypeName(): React.ReactNode {
        return 'Должность';
    }

    protected createEntity(): Position {
        return new Position();
    }

    onKeyChange(e: SelectChangeEvent) {
        this.state.entity.Keys[e.target.name] = parseInt(e.target.value) || 0;
    }
    onFieldChange(field: string, value: any) {
        super.onFieldChange(field, value);

        if (field == 'SuperAccess') {
            this.forceUpdate();
        }
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            flexDirection: 'column',
            overflow: 'hidden',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    buildGroups() {
        const result = [];
        const disabled = !!this.state?.entity?.SuperAccess;
        for (var gr in keys) {
            result.push(<Typography key={gr} variant="h5" sx={{ my: 1 }}>{gr}</Typography>);

            var gro = keys[gr];
            for (var key in gro.keys) {
                let grk: GRInfo = isString(gro.keys[key]) ? {
                    name: gro.keys[key] as string,
                    variants: keys[gr].variants
                } : gro.keys[key] as GRInfo;

                result.push(<FormControlLabel
                    labelPlacement="start"
                    control={<Checkbox
                        name={gro.prefix + key}
                        defaultChecked={!!this.state?.entity?.Keys[gro.prefix + key]}
                        onChange={e => this.state.entity.Keys[e.target.name] = e.target.checked ? 1 : 0} />}
                    label={grk.name} />
                    /**
                        <div key={gr + key + disabled} className="key-value">
                    <OutlinedInput key="key" disabled={true} sx={styles.input} inputProps={{ style: styles.inputInner }} value={grk.name} />
                    <Select key="value"
                        sx={styles.input}
                        name={gro.prefix + key}
                        onChange={this.onKeyChange}
                        defaultValue={this.state?.entity?.Keys[gro.prefix + key]?.toString() || ''}
                        disabled={disabled}>
                        {grk.variants.map(i => <MenuItem key={i} value={i}><Typography color={colors[i]}>{variants[i]}</Typography></MenuItem>)}
                    </Select>
                </div>/**/
                );
            }
        }

        return result;
    }

    protected buildItems(entity?: Position | undefined): React.ReactNode {
        return <Paper sx={isMobile() ? { flex: 1 } : { flex: entity?.SuperAccess ? 'none!important' : 1, minWidth: 500 }}>
            <FormTextInput required={true} label="Наименование" name="Name" form={this} entity={entity} />

            <Box key={entity ? (entity.Id?.toString() + entity.SuperAccess?.toString()) : '0'} sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                "& > .MuiFormControlLabel-labelPlacementStart": {
                    justifyContent: 'space-between',
                    paddingRight: '8.9em'
                }
            }}>
                <div key="header" style={{ display: 'flex' }}>
                    <Typography variant="h6" sx={{ my: 1, mr: 'auto' }}>Права доступа</Typography>
                    <FormControlLabel control={<Checkbox name="SuperAccess" checked={!!entity?.SuperAccess} onChange={this.onChange} />} label="Полный доступ" />
                </div>
                {this.state?.entity?.SuperAccess ? null : this.buildGroups()}
            </Box>

            <Box sx={{ display: 'flex' }}>
                <div style={{ flex: 1 }} />
                <Button variant="contained" type="submit">Сохранить</Button>
            </Box>
        </Paper>;
    }
}