import { AddRounded, ArrowRight, CarRentalRounded, CheckRounded, ChevronRight, CloseRounded, ConstructionRounded, FeedbackRounded, LocalTaxiRounded, MenuRounded, NewspaperRounded } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, InputAdornment, List, ListItemAvatar, ListItemButton, ListItemText, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { ReactNode, useMemo, useState } from "react";
import NavLink, { NavIconButton } from "../../components/NavLink";
import { TextInput } from "../../components/form/TextInput";
import { RequestStatus } from "../../models/Enums";
import { useCachedStore, useRemoteData, useRemoteList } from "../../models/Hooks";
import { Bill, EntryPass, Meter, Post, Request } from "../../models/Types";
import AppDrawer from "../../nav/AppDrawer";
import { theme } from "../../theme";
import { isMobile } from "../../utils";
import { meterTariffStore } from "../measure/MeterTariffCombo";

function getWidth(w?: number) {
    if (isMobile()) {
        return 'auto';
    }

    w || (w = 1);
    // return w * 345 + (16 * (w - 1));

    return 'calc(' + (w * 100 / 3) + '% - ' + (16 * (w - 1)) + 'px)';
}

interface MeasureWidgetData {
    Measures: {
        Cur: number
        Prev?: number
        MeterTariffId: number
    }[]

    Meters: {
        All: number,
        Overdue: number
    }[]
}

function MeasuresWidget() {
    const [data] = useRemoteData<MeasureWidgetData>('MeasureAnalytic');
    const [meters] = useCachedStore(meterTariffStore);

    if (data && !data.Measures) {
        return <></>;
    }

    return <Card sx={{ flex: 1 }}>
        <CardHeader title="Показания счетчиков" subheader="Принимаются с 20 по 25 число каждого месяца" />
        <CardContent sx={{ gap: 2, display: 'flex', flexDirection: 'column' }} >
            {meters ? data?.Measures.map(x => <TextInput key={x.MeterTariffId}
                name={x.MeterTariffId}
                onChange={e => { }}
                label={meters.find(t => t.Id == x.MeterTariffId)?.Value || x.MeterTariffId.toString()}
                value={x.Cur}
                inputProps={{
                    type: 'number',
                    min: x.Prev,
                    endAdornment: <InputAdornment position="end">{x.Prev}</InputAdornment>
                }} />) : null}
            <Box>
                <Button variant="contained" sx={{ ml: 'auto' }}>Сохранить</Button>
            </Box>
        </CardContent>
    </Card>;
}

function PaymentWidget() {
    const [data] = useRemoteData<Bill[]>('BillActiveList');

    return <Card sx={{ flex: 2 }}>
        <CardHeader title="Квитанции на оплату" />
        <CardContent sx={{ gap: 2, display: 'flex', flexDirection: isMobile() ? 'column' : 'row', flex: 1 }}>
            <Box sx={{ flex: 1, gap: 2, display: 'flex', flexDirection: 'column' }}>
                {data?.map(x => <TextInput
                    name={x.Id}
                    onChange={e => { }}
                    label={moment(x.CreateDate).format('MMMM Y')}
                    value={x.Amount} />)}
            </Box>
            <Divider orientation="vertical" />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Button variant="contained">Оплатить картой</Button>
                <Button variant="contained">Сформировать Квитанцию</Button>
            </Box>
        </CardContent>
    </Card>;
}

function MeterWidget() {
    const [data] = useRemoteList<Meter>('MeterList');
    return <Card sx={{ flex: 1 }}>
        <CardHeader title="Счетчики" subheader="В случае просрочки поверки, показания счетчика не принимаются" />
        <CardContent sx={{ gap: 2, display: 'flex', flexDirection: 'column' }} >
            {data?.map(x => <DatePicker
                label={x.Name ? (x.Name + ' (' + x.Number + ')') : x.Number}
                value={moment(x.NextCheck)}
                readOnly={true} />)}
        </CardContent>
    </Card>;
}

function PostsWidget() {
    const [data] = useRemoteList<Post>(`PostList?query=${JSON.stringify({ Sort: { Property: 'CreateDate', Direction: 1 }, Count: 10, LazyLoad: true })}`);
    const [t, st] = useState(0);

    const tags = useMemo(() => data?.reduce((a, b) => {
        b.HashTags?.filter(x => a.indexOf(x) < 0).forEach(x => a.push(x));

        return a;
    }, [] as string[]), [data])

    return <Card sx={{ flex: 3 }}>
        <CardHeader title={<>Новости <NavLink route={[['page', 'news'], ['id']]}>Все <ChevronRight /></NavLink></>} />
        <Box sx={{ overflow: 'auto hidden', mt: -2.5 }}>
            <table cellSpacing={20}>
                <tbody>
                    <tr>
                        {data?.filter(x => !t || !tags || x.HashTags?.indexOf(tags[t - 1]) >= 0)
                            .map(x => <td>
                                <NavLink key={x.Id} route={[['page', 'news'], ['id', x.Id]]} sx={{
                                    width: 400, border: '1px solid #D2D8E8', borderRadius: 1, p: 4, height: '12em', textDecoration: 'none', color: 'inherit',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden'
                                }}>
                                    <Typography fontSize={18} fontWeight={400} height="4.5em" overflow="hidden" textOverflow="ellipsis">{x.Name}</Typography>
                                    <Box display="flex" justifyContent="space-between" >
                                        <Typography variant="caption">{moment(x.CreateDate).calendar()}</Typography>
                                        <Button startIcon={<ArrowRight />} variant="contained" color="info" sx={{
                                            minWidth: 0,
                                            p: 0,
                                            '& > .MuiButton-icon': {
                                                margin: 0
                                            }
                                        }} />
                                    </Box>
                                </NavLink>
                            </td>)}
                    </tr>
                </tbody>
            </table>
        </Box>
    </Card>;
}

const requestStatusIcons: { [status: keyof RequestStatus]: () => ReactNode } = {
    [RequestStatus.Implementing]: () => <ConstructionRounded />,
    [RequestStatus.Completed]: () => <CheckRounded />,
    [RequestStatus.Canceled]: () => <CloseRounded />,
    [RequestStatus.Rejected]: () => <CloseRounded />
}
const requestStatusColors: { [status: keyof RequestStatus]: string } = {
    [RequestStatus.Completed]: theme.palette.success.light,
    [RequestStatus.Canceled]: theme.palette.error.light
}
function RequestsWidget() {
    const [data] = useRemoteList<Request>(`RequestList?query=${JSON.stringify({ Sort: { Property: 'CreateDate', Direction: 1 }, Count: 4, LazyLoad: true })}`);

    return <Card sx={{ flex: 1 }}>
        <CardHeader
            titleTypographyProps={{ display: 'flex' }}
            title={<>
                Заявки
                <NavIconButton size="small" sx={{ ml: 'auto' }} route={[['page', 'request'], ['id', 'new']]}>
                    <AddRounded />
                </NavIconButton>
            </>} />
        <List disablePadding>
            {data?.map(x => <ListItemButton key={x.Id} data-route={'page=request&id=' + x.Id}>
                <ListItemAvatar>
                    <Avatar sx={x.Status && requestStatusColors[x.Status] ? { bgcolor: requestStatusColors[x.Status] } : null}>
                        {x.Status && requestStatusIcons[x.Status] ? requestStatusIcons[x.Status]() : <FeedbackRounded />}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={x.Name} secondary={moment(x.CreateDate).calendar()} />
            </ListItemButton>)}
        </List>
    </Card>;
}

function EntryPassWidget() {
    const [data] = useRemoteList<EntryPass>(`EntryPassActive?query=${JSON.stringify({ Sort: { Property: 'Expire', Direction: 1 }, Count: 4, LazyLoad: true })}`);

    return <Card sx={{ flex: 1 }}>
        <CardHeader
            titleTypographyProps={{ display: 'flex' }}
            title={<>
                Пропуска
                <NavIconButton size="small" sx={{ ml: 'auto' }} route={[['page', 'entrypass'], ['id', 'new']]}>
                    <AddRounded />
                </NavIconButton>
            </>} />
        <List disablePadding>
            {data?.map(x => <ListItemButton key={x.Id} data-route={'page=entrypass&id=' + x.Id}>
                <ListItemAvatar>
                    <Avatar>{x.Expire ? <LocalTaxiRounded /> : <CarRentalRounded />}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={x.Name || x.CarNumber} secondary={x.Expire ? moment(x.Expire).calendar() : null} />
            </ListItemButton>)}
        </List>
    </Card>;
}

function TasksWidget() {
    return <Card sx={{ width: getWidth() }}>
        <CardHeader title="Задачи" subheader={<>Активных: <b>1</b> Просрочено: <b>0</b></>} />
        <CardContent>
            <FormGroup>
                <FormControlLabel control={<Checkbox />} label={<NavLink route={[['guide', 'welcome']]}>Пройти ознакомительный тур</NavLink>} />
            </FormGroup>
        </CardContent>
    </Card>;
}

export default function Dashboard() {
    const [open, setOpen] = useState(false);

    if (isMobile()) {
        return <>
            <Toolbar>
                <IconButton onClick={() => setOpen(!open)}><MenuRounded /></IconButton>
            </Toolbar>
            <Box sx={{ overflow: 'auto', p: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <MeasuresWidget />
                    <PaymentWidget />
                    <EntryPassWidget />
                    <RequestsWidget />
                    <PostsWidget />
                </Box>
            </Box>
            <AppDrawer key={'drawer' + open} open={open} />
        </>;
    } else {
        return <Box sx={{
            py: 2, gap: 2, display: 'grid', overflowY: 'auto',
            '& > .MuiBox-root': {
                gap: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'center', overflow: 'hidden',
                '& > .MuiCard-root': {
                    display: 'flex', flexDirection: 'column',
                    '& .MuiCardHeader-title': {
                        fontWeight: 700,
                        '& > a.MuiLink-root[data-route]': {
                            textDecoration: 'none',
                            fontSize: '.5em',
                            fontWeight: 400,
                            marginLeft: '1em',
                            display: 'inline-flex',
                            alignItems: 'center',
                            '& > .MuiSvgIcon-root': {
                                fontSize: 'inherit'
                            }
                        }
                    }
                }
            }
        }}>
            <Box>
                <RequestsWidget />

                <PaymentWidget />
            </Box>

            <Box>
                <EntryPassWidget />

                <MeasuresWidget />

                <MeterWidget />
            </Box>

            <Box>
                <PostsWidget />
            </Box>
        </Box>;
    }
}