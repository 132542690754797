import { useTheme } from "@mui/material";

export default function Title({ color }: any) {
    const theme = useTheme();

    return <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="10.2564" fill={color || theme.palette.primary.main} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3465 16.5047H14.4569L23.3094 25.549V36.1743H25.9916V28.2894L33.7093 36.1743H39.745V33.4921H34.8372L26.5237 24.9984L34.8372 16.5047H39.745V13.8225H33.7093L25.9916 21.7074V13.8225H23.3094V21.7145L15.5848 13.8225H9.3465V16.5047Z" fill="white" />
    </svg>

}