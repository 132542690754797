import { Position } from "../../../models/Types";
import RemoteCombo, { configureSuggestStore, RemoteComboProps } from "../../../components/form/RemoteCombo";

const cmd = 'PositionSuggest';

export const positionStore = configureSuggestStore<Position>(cmd, null, 30 * 60, ['PositionSave']);

export default function PositionCombo(props: RemoteComboProps<Position>) {
    return <RemoteCombo<Position>
        typeId="Position"
        store={positionStore}
        cmd={cmd}
        multiple={true}
        {...props}
        ref={undefined} />;
}