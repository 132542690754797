import { PaletteColorOptions, alpha, createTheme, getContrastRatio } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';

const defaultPalette = createPalette({});

declare module "@mui/material/styles" {
    interface PaletteOptions {
        button?: PaletteColorOptions;
    }
}

declare module '@mui/material' {
    interface ButtonPropsColorOverrides {
        button: true
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0260F9'
        },
        info: {
            contrastText: '#0260F9',
            main: '#EBF5FF',
            light: alpha('#EBF5FF', 0.5),
            dark: alpha('#EBF5FF', 0.9)
        },
        secondary: {
            main: '#1C252E',
            light: '#FBF8F3'
        },
        text: {
            disabled: '#7B818F'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFF'
                }
            }
        },
        MuiToolbar: {
            defaultProps: {
                color: 'transparent'
            }
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 2px 0px',
                    margin: 2
                },
                rounded: {
                    borderRadius: '.75rem'
                },
                root: {
                    '& > .MuiFormControl-root:first-child': {
                        marginTop: '1rem'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 500,
                    borderRadius: 0,
                    '&.Mui-selected': {
                        color: "secondary",
                        fontWeight: 600
                    }
                }
            }
        },
        MuiSwitch: {
            defaultProps: {
                color: 'primary'
            }
        },
        MuiCheckbox: {
            defaultProps: {
                color: 'primary'
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: "small"
            }
        },
        MuiToggleButton: {
            defaultProps: {
                size: "small"
            }
        },
        MuiOutlinedInput: {/**
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    backgroundColor: '#fff'
                },
                input: {
                    backgroundColor: '#fff',
                    //fontSize: '1.1735rem'
                }
            },/**/
            defaultProps: {
                size: "small",
                fullWidth: true,
                minRows: 2,
                maxRows: 6
            }
        },
        MuiTextField: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginLeft: 0
                }
            }
        },
        MuiFormLabel: {/**
            styleOverrides: {
                root: {
                    marginTop: 8,
                    fontWeight: 500,
                    fontSize: '1.0745rem'
                }
            }/**/
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    boxShadow: 'none'
                },
                outlined: {
                    backgroundColor: '#fff'
                }
            }
        }
    },
    typography: {
        fontFamily: [
            'Manrope',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')
    }
});